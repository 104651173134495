<template>
  <div>
    <pwd-contair>
      <div slot="menu">密码重置</div>
      <div slot="pwdContair">
        <div class="pwdContair">
          <step-contair  :active="3"></step-contair>
          <change-state>
              <div slot="img">
                  <img src="../../../../assets/supplier/success.png" />
              </div>
              <div slot="text">
                  已成功重置密码，正在为您登录
              </div>
              <div slot="state">
                  <span class="co_7474">若没有跳转，请点击登录</span>
                  <div style="display: inline-block; cursor: pointer;" @click="toLogin()" class="co_09f F12 Plf5">去登录>></div>
              </div>
          </change-state>
        </div>
      </div>
    </pwd-contair>
  </div>
</template>
<script>
import ChangeState from '@/components/supplier/pwdContair/changeState.vue';
import pwdContair from "@/components/supplier/pwdContair/pwdContair.vue";
import StepContair from "@/components/supplier/pwdContair/stepContair";
export default {
  components: { pwdContair, StepContair, ChangeState },
  methods: {
    toLogin() {
      this.$router.push("/userLogin")
    }
  }
};
</script>
<style scoped lang="scss">
</style>